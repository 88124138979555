// React
import { useCallback, useEffect, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'

// Redux
import { RootState } from '../../store/reducer'
import { useDispatch, useSelector } from 'react-redux'

// Actions
import { fetchVenueInfoData, venueInfoUpdated } from '../../store/venueInfo'

// Languages
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

// Axios instances
import { venueManagerApi } from '../../api/axios.config'

// Components
import TagmeModal from '../../Components/TagmeModal'
import TagmeButton from '../../Components/TagmeButton'
import WaitlistHeader from '../../Components/WaitlistHeader'
import PositionIndicator from '../../Components/PositionIndicator'
import DisclamerServiceTerms from '../../Components/DisclamerServiceTerms'

// Styles
import { GlobalStyle } from '../../styles/global'
import { ContainerStyles, FooterStyles, ClientPositionStyles } from './styles'

// Interfaces
import { LanguageTypes } from '../../common/interfaces'
import { SuccessUpdateVenueInfo } from '../../store/interfaces'
import { WaitListInfo, WaitListResponseParams, OperationModeType } from './interfaces'
import { ModalProps, ModalTypes, ButtonTypes } from '../../Components/TagmeModal/interface'

// Utils
import { handleSessionStorage } from '../../utils/sessionStorageManagement'

// Pusher
import { PusherService } from '../../Services/pusher.service'

// Errors
import * as Sentry from '@sentry/react'
import { ErrorsList } from '../../common/errors'

// import '@lottiefiles/lottie-player'
import { COLORS } from '../../common/constants'

//TextsDinamics
import { venuesListSpecial } from '../../common/textDinamic'

// Images
import handleCalled from '../../assets/images/alert-called.svg'
import handleSeated from '../../assets/images/alert-seated.svg'

const pusherService = new PusherService()

function WaitList() {
	const defaultWaitlist = useMemo(
		() => ({
			status: 'active',
			globalPos: 0,
			displayHelpers: {
				partyLabel: '',
				priorityText: '',
			},
			pos: 0,
			priority: null,
			_id: '',
			arrivedAt: '',
			customer: {
				_id: '',
				name: '',
			},
			customerTab: '',
			estimatedTime: '',
			origin: {
				_id: '',
				label: '',
			},
			partySize: 1,
			venue: '',
			hideCustomerPosition: false,
			operationMode: OperationModeType.ADVANCED,
		}),
		[]
	)

	const [modalInfo, setModalInfo] = useState<ModalProps>({
		show: false,
		type: ModalTypes.error,
	})

	const [waitlistState, setWaitlistState] = useState<WaitListInfo>(defaultWaitlist)

	const [waitlistPusher, setWaitlistPusherState] = useState(false)

	const [hasBeenCalled, setHasBeenCalled] = useState<boolean>(false)

	const [hasBeenSeated, setHasBeenSeated] = useState<boolean>(false)

	// Handle the text translation
	const { t } = useTranslation()

	// Extract the current language from the i18next
	const language = i18n.language as LanguageTypes

	const dispatch = useDispatch()

	// Get waitlistId by url parameter
	const { waitlistId } = useParams<WaitListResponseParams>()

	// Access the globalState venueInfo
	const venueInfo = useSelector((state: RootState) => state.entities.venueInfo)

	// Position to show alert "Stay Close"
	const positionAlertStayClose = 3

	// --------------------------- HANDLES
	const handleToSmartlink = useCallback(() => {
		window.location.href = `${process.env.REACT_APP_RESERVATION_WIDGET_URL}/smartlink/${venueInfo._id}`
	}, [venueInfo._id])

	const handleGetWaitlistStatus = useCallback(
		async (venueId: string) => {
			try {
				const responseWaitlistData = await venueManagerApi.get(`/waitlists/${waitlistId}/${venueId}/status`)

				setHasBeenCalled(false)
				setHasBeenSeated(false)

				if (responseWaitlistData?.data?.notifiedAt) {
					setHasBeenCalled(true)
					setHasBeenSeated(false)
				}

				if (responseWaitlistData?.data?.seatedAt) {
					setHasBeenSeated(true)
					setHasBeenCalled(false)
				}

				if (responseWaitlistData?.data?.canceledAt) {
					setWaitlistState(previousWaitlistState => ({
						...previousWaitlistState,
						status: null,
					}))
				}
			} catch (error) {
				Sentry.setTag('venueId', venueId)
				Sentry.captureException(new Error(ErrorsList.FAILED_TO_GET_WAITLIST_INFORMATION))
			}
		},
		[waitlistId]
	)

	// Modal handles
	const handleCloseModal = useCallback(() => {
		setModalInfo(previousModalInfoState => ({ ...previousModalInfoState, show: false }))
	}, [])

	const handleTagmeModal = (props: ModalProps) => {
		return (
			<TagmeModal
				show={props.show}
				type={props.type}
				onClose={props.onClose}
				closable={props.closable}
				closeOutsideClick={props.closeOutsideClick}
				title={props.title}
				message={props.message}
				buttons={props.buttons}
			/>
		)
	}

	const handleShowModalLeaveWaitlist = () => {
		setModalInfo({
			show: true,
			onClose: handleCloseModal,
			type: ModalTypes.noIcon,
			message: 'Would you like to leave the waitlist?',
			buttons: [
				{
					label: 'Yes',
					action: () => handleLeaveWaitlist(),
					type: ButtonTypes.primary,
					id: 'confirm_leave_waitlist_button',
				},
			],
		})
	}

	const handleGetWaitlistData = useCallback(async () => {
		try {
			const responseWithPosition = await venueManagerApi.get(`/waitlists/${waitlistId}/position`)
			const responseWithPositionData = responseWithPosition.data

			const { waitlist, waitlistSettings } = responseWithPositionData

			setWaitlistState({
				...waitlist,
				hideCustomerPosition: waitlistSettings.hiddenCustomerPosition,
				operationMode: waitlistSettings.operationMode,
			})

			handleGetWaitlistStatus(waitlist.venue)
		} catch (error) {
			setModalInfo({
				show: true,
				onClose: handleCloseModal,
				type: ModalTypes.error,
				message: 'Failed to get waitlist information.',
				buttons: [
					{
						label: 'OK',
						action: handleCloseModal,
					},
				],
			})

			Sentry.captureException(new Error(ErrorsList.FAILED_TO_GET_WAITLIST_INFORMATION_WITH_POSITION))
		}
	}, [handleCloseModal, handleGetWaitlistStatus, waitlistId])

	const handleLeaveWaitlist = useCallback(async () => {
		try {
			const data = {
				logs: { customer: waitlistState.customer?._id, actionType: 'Remove' },
				customer: waitlistState.customer?._id,
				cancelReason: 'Customer',
			}

			await venueManagerApi.put(`/waitlists/${waitlistId}/${waitlistState.venue}/cancel`, data)

			setWaitlistState(defaultWaitlist)
			handleCloseModal()
		} catch (error) {
			setModalInfo({
				show: true,
				onClose: handleCloseModal,
				type: ModalTypes.error,
				message: 'Failed to leave the waitlist.',
				buttons: [
					{
						label: 'OK',
						action: handleCloseModal,
					},
				],
			})

			Sentry.setTag('venueId', waitlistState.venue)
			Sentry.captureException(new Error(ErrorsList.FAILED_TO_LEAVE_WAITLIST))
		}
	}, [defaultWaitlist, handleCloseModal, waitlistId, waitlistState.customer?._id, waitlistState.venue])

	const handleShowMenu = () => {
		let url = `${process.env.REACT_APP_MENU_URL}/menu/${venueInfo._id}`

		if (process.env.REACT_APP_NODE_ENV === 'production' && venueInfo.slug) {
			url = `${process.env.REACT_APP_TAGME_URL}/menu/${venueInfo.slug}`
		}

		window.open(url, '_blank')
	}

	const handleOnDestroy = useCallback(() => {
		if (waitlistPusher && pusherService?.channel && pusherService?.pusher) {
			pusherService.channel.unbind()
			pusherService.pusher.unsubscribe(pusherService.channel)

			setWaitlistPusherState(false)
		}
	}, [waitlistPusher])

	// --------------------------- USE EFFECTS
	// Get waitlist information from backend
	useEffect(() => {
		handleGetWaitlistData()
	}, [handleGetWaitlistData, waitlistId])

	// Update the venueInfo
	useEffect(() => {
		async function fetchData() {
			try {
				// Fetch the information available in the sessionStorage
				const { sessionStorageFilled, sessionStorageId, parsedSessionStorage } =
					handleSessionStorage('venueInfo')

				// It checks if the waitlistState is properly filled
				const venueId = waitlistState.venue

				// The conditionals bellow only will be checked if the venueInfo (global state) is not filled
				// If the sessionStorage is properly filled, the venueInfo will be set based in this information,
				// avoiding unncessary resquests
				if (sessionStorageFilled && sessionStorageId === venueId) {
					return dispatch(venueInfoUpdated(parsedSessionStorage))
				}

				// If the sessionStorage is not properly filled and the venueId is available,
				// it will fetch the necessary information in the API and update the venueInfo.
				if (venueId) {
					// Thunk Action for fetching data in the API and updating the global state venueInfo
					const successUpdateVenueInfo = (await dispatch(
						fetchVenueInfoData(venueId)
					)) as SuccessUpdateVenueInfo

					if (successUpdateVenueInfo.errorsMessages.length > 0) {
						let hasOnlyThemeError = false

						for (const errorMessage of successUpdateVenueInfo.errorsMessages) {
							hasOnlyThemeError =
								successUpdateVenueInfo.errorsMessages.length === 1 && errorMessage.includes('THEME')

							Sentry.setTag('venueId', waitlistState.venue)
							Sentry.captureMessage(errorMessage, Sentry.Severity.Warning)
						}

						if (!hasOnlyThemeError) {
							setModalInfo({
								show: true,
								closable: false,
								type: ModalTypes.noIcon,
								message: 'Waitlist is currently inaccessible.',
								buttons: [
									{
										label: 'OK',
										action: handleToSmartlink,
									},
								],
							})
						}
					}
				}
			} catch (err) {
				setModalInfo({
					show: true,
					onClose: handleCloseModal,
					type: ModalTypes.error,
					message: 'Failed to get costumer information.',
					buttons: [
						{
							label: 'OK',
							action: handleCloseModal,
						},
					],
				})
			}
		}

		// If the venueInfo is filled, we don't need to proceed, so the fetchVenueData function bellow won't be called
		if (!venueInfo._id) {
			fetchData()
		}
	}, [dispatch, handleCloseModal, handleToSmartlink, venueInfo._id, waitlistState])

	// Update Pusher
	useEffect(() => {
		if (!waitlistPusher && venueInfo?._id) {
			pusherService.subscribe(venueInfo._id)

			// add, seat, call, remove, update, schedule, removeScheduled
			const listEventsNamesUpdateWaitlist = ['remove', 'update', 'seat']

			for (const event of listEventsNamesUpdateWaitlist) {
				pusherService.channel.bind(event, () => {
					handleGetWaitlistData()
				})
			}

			pusherService.channel.bind('call', (data: any) => {
				if (data._id === waitlistId) {
					setHasBeenCalled(true)
					setHasBeenSeated(false)
				}
			})

			pusherService.channel.bind('add', (data: any) => {
				if (data._id === waitlistId) {
					handleGetWaitlistData()
				}
			})

			setWaitlistPusherState(true)
		}
	}, [handleGetWaitlistData, venueInfo, waitlistId, waitlistPusher])

	// On destroy
	useEffect(() => {
		return () => handleOnDestroy()
	}, [handleOnDestroy])
	// --------------------------- USE EFFECTS

	// HTML handles
	const handleQueuePositionPhrase = () => {
		const { _id: venueId } = venueInfo
		if (waitlistState.operationMode === OperationModeType.ADVANCED) {
			return (
				<p>
					{venuesListSpecial.includes(venueId as string)
						? t('Your position on the reserve list: ')
						: t('Your position in waitlist for a table for ')}

					<strong>
						{waitlistState.partySize} {waitlistState.partySize === 1 ? t('person') : t('people')}
					</strong>
					<br />
				</p>
			)
		}
	}

	const handleClientPosition = () => {
		if (!hasBeenCalled && !hasBeenSeated) {
			if (waitlistState.hideCustomerPosition) {
				return (
					<div id="div_hide_customer_position">
						{t(
							'You are in our online waitlist and soon you will be called. Pay attention to your SMS messages.'
						)}
					</div>
				)
			}

			if (waitlistState.status === 'active') {
				return (
					<>
						<ClientPositionStyles
							colorBackground={venueInfo.color.background || COLORS.background}
							colorMain={venueInfo.color.main || COLORS.main}
						>
							{handleQueuePositionPhrase()}
							<small>{t('Updated in real time')}</small>
							<PositionIndicator position={waitlistState.pos || waitlistState.globalPos} />
							<div>
								<p>
									{t('General Position')}:{' '}
									<span className="highlighted" data-cy="highlight1">
										{waitlistState.globalPos}º
									</span>
									<br />
									{waitlistState.customerTab ? (
										<>
											{t('Order pad')}:{' '}
											<span className="highlighted" data-cy="highlight2">
												Nº {waitlistState.customerTab}
											</span>
										</>
									) : null}
								</p>
							</div>
						</ClientPositionStyles>
					</>
				)
			}

			return (
				<ClientPositionStyles
					colorBackground={venueInfo.color.background || COLORS.background}
					colorMain={venueInfo.color.main || COLORS.main}
				>
					<div className="div_attention">
						<p>{t('You are no longer on the waitlist.')}</p>
					</div>
				</ClientPositionStyles>
			)
		}
	}

	const handleButtonLeaveWaitlist = () => {
		const urlParams = new URLSearchParams(window.location.search);
		const isShared = urlParams.get('shared') === 'true';

		if (waitlistState.status === 'active' && !hasBeenCalled && !hasBeenSeated && !isShared) {
			return (
				<div id="div_button_leave_waitlist">
					<button
						className="outline_tagme_button"
						onClick={handleShowModalLeaveWaitlist}
						data-cy="leave_waitlist_button"
					>
						{t('Leave the waitlist')}
					</button>
				</div>
			)
		}
	}

	const showCopiedNotification = () => {
		const notification = document.createElement('div');
		notification.classList.add('copied-notification');
		notification.textContent = t('Link Copied');

		const shareButton = document.getElementById('div_button_share_waitlist');

		if (shareButton) {
			shareButton.style.position = 'relative';
			shareButton.appendChild(notification);

			notification.style.display = 'block';

			setTimeout(() => {
				notification.style.display = 'none';
				shareButton.removeChild(notification);
			}, 2000);
		}
	};

	const shareCurrentPage = async () => {
		try {
			const sharedUrl = `${window.location.href}?shared=true`;
			const venueName = venueInfo.venueName[language] || venueInfo.venueName.pt;

			if (navigator.share) {
				await navigator.share({
					title: `Minha Posição na Lista de Espera do ${venueName}`,
					text: `Confira minha posição na lista de espera do ${venueName}!`,
					url: sharedUrl
				});
			} else {
				await navigator.clipboard.writeText(sharedUrl);
				showCopiedNotification();
			}
		} catch (error) {
			const e = error as Error;

			if (e.name === 'AbortError' || e.message.includes('The user aborted a request')) {
			} else {
				setModalInfo({
					show: true,
					onClose: handleCloseModal,
					type: ModalTypes.error,
					message: 'We were unable to share your position on the waitlist. Please try again.',
				});
				Sentry.captureException(e);
			}
		}
	};

	const handleButtonShareWaitlist = () => {
		const urlParams = new URLSearchParams(window.location.search);
		const isShared = urlParams.get('shared') === 'true';

		if (hasBeenCalled || hasBeenSeated || isShared || waitlistState.status !== 'active') {
			return null;
		}

		return (
			<div id="div_button_share_waitlist">
				<button
					className="outline_tagme_button"
					onClick={shareCurrentPage}
					data-cy="share_waitlist_button"
				>
					{t('Share Waitlist Position')}
				</button>
			</div>
		);
	};

	const handleShowAlertStayClose = () => {
		if (waitlistState.pos <= positionAlertStayClose && !hasBeenCalled && !hasBeenSeated) {
			return (
				<div className="div_attention">
					<p>
						{venuesListSpecial.includes(venueInfo._id as string)
							? `${t('Your turn is coming! Go to the establishment.')}`
							: `${t('Head to the nearby restaurant, your table should be ready soon.')}`}
					</p>
				</div>
			)
		}
	}

	const handleHasBeenCalled = () => {
		if (hasBeenCalled) {
			return (
				<div className="div_call">
					<img
						src={handleCalled}
						alt="Check Mark"
						style={{
							display: 'block',
							margin: 'auto',
						}}
					/>

					<h1>{t(`It's your turn!`)}</h1>
					<p>{t('We are waiting for you. We hope your experience is perfect!')}</p>
				</div>
			)
		}
	}

	const handleHasBeenSeated = () => {
		const { _id: venueId } = venueInfo
		if (hasBeenSeated) {
			return (
				<div className="div_seat">
					<img
						src={handleSeated}
						alt="Check Mark"
						style={{
							display: 'block',
							margin: 'auto',
						}}
					/>

					<h1>{t(`Welcome!`)}</h1>
					<p>
						{venuesListSpecial.includes(venueId as string)
							? `${t(`It's your turn in and our team is ready to better serve you.`)}`
							: `${t(`It's your turn in our restaurant and our team is ready to better serve you.`)}`}
					</p>
				</div>
			)
		}
	}

	const handleShowPriority = () => {
		if (!hasBeenCalled && !hasBeenSeated) {
			return (
				<div id="div_priority">
					{waitlistState.priority ? (
						<p>{waitlistState.displayHelpers.priorityText}</p>
					) : (
						<p>
							{t('Some customers are in preferred waitlist. It takes at least half the people to enter.')}
						</p>
					)}
				</div>
			)
		}
	}

	const handleDisclamerServiceTerms = () => {
		if (!hasBeenCalled && !hasBeenSeated) {
			return (
				<DisclamerServiceTerms
					colorMain={venueInfo.color.main}
					venueName={venueInfo.venueName[language]}
					minForm={true}
				/>
			)
		}
	}

	const handleHasMenu = () => {
		if (venueInfo.hasMenu) {
			return (
				<FooterStyles
					colorBackground={venueInfo.color.background || COLORS.background}
					colorBorder={venueInfo.color.border || COLORS.divider}
				>
					<div className="divider"></div>

					<p>{t('Have you thought about what to order?')}</p>

					<TagmeButton
						type="button"
						label={t('Take a look at our menu')}
						colorMain={venueInfo.color.main}
						disabled={false}
						handleClick={handleShowMenu}
					/>
				</FooterStyles>
			)
		}
	}

	return (
		<ContainerStyles
			colorBackground={venueInfo.color.background || COLORS.background}
			colorMain={venueInfo.color.main || COLORS.main}
			colorBorder={venueInfo.color.border || COLORS.border}
		>
			<WaitlistHeader />

			{handleClientPosition()}

			{handleButtonLeaveWaitlist()}

			{handleButtonShareWaitlist()}

			{handleShowAlertStayClose()}

			{handleShowPriority()}

			{handleDisclamerServiceTerms()}

			{handleHasBeenCalled()}

			{handleHasBeenSeated()}

			{handleHasMenu()}

			{handleTagmeModal(modalInfo)}

			<GlobalStyle colorBackground={venueInfo.color.background || '#f5f2ea'} modalOpened={modalInfo.show} />
		</ContainerStyles>
	)
}

export default WaitList
